import { getLocalData } from "../../Utils/LocalStorage";
import { serverUrl } from "../../api";
import * as types from "./actiontypes";
import axios from "axios";

export const createClient = (data) => (dispatch) => {
  dispatch({ type: types.CREATE_CLIENT_REQUEST });
  return axios.post(`${serverUrl}/client/add`, data, {
      headers: {
        Authorization: getLocalData("maintoken"),
      },
    })
    .then((res) => {
      // console.log(res);
      return dispatch({ type: types.CREATE_CLIENT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({ type: types.CREATE_CLIENT_FAILURE });
    });
};

export const getClient=(id)=> (dispatch) => {
  dispatch({ type: types.GET_CLIENT_REQUEST });
  return axios.get(`${serverUrl}/client/${id}`)
    .then((res) => {
      // console.log(res)
      return dispatch({ type: types.GET_CLIENT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_CLIENT_FAILURE });
    });
};

// export const verifyOtp = (data) => (dispatch) => {
//     dispatch({ type: types.OTP_REQUEST })
//     return axios.post(`${serverUrl}/user/verifyotp`, data)
//         .then((res) => {

//           return  dispatch({ type: types.OTP_SUCCESS, payload: res.data })
//         }).catch((err) => {
//            return dispatch({ type: types.OTP_FAILURE })
//         })
// }

// export const resendOtp = (data) => (dispatch) => {
//     dispatch({ type: types.OTP_REQUEST })
//     return axios.post(`${serverUrl}/user/resendotp`, data)
//         .then((res) => {

//           return  dispatch({ type: types.OTP_SUCCESS, payload: res.data })
//         }).catch((err) => {
//            return dispatch({ type: types.OTP_FAILURE })
//         })
// }
