import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Register from "./Register";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import LoginComponent from "./Login";
import RegistrationComponent from "./Register";
import CA_addOfficepage from "./CA_addOfficepage";
import Officedashboard from "./OfficeDashboard";
import Add_client from "./Add_client";
import Add_employee from "./Add_employee";

import Add_clientWork from "./Add_clientWork";
import Dashboard from "./PGPMS";

const Allroutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RegistrationComponent />}></Route>
      {/* <Route path="/Dashboard" element={<Dashboard />}></Route> */}
      <Route path="/register" element={<Register />}></Route>
      <Route path="/login" element={<LoginComponent />}></Route>
      <Route path="/home" element={<Home />}></Route>
      <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
      <Route path="/officecreate" element={<CA_addOfficepage />}></Route>
      <Route path="/officedashboard" element={<Officedashboard />}></Route>
      <Route path="/addclient/:id" element={<Add_client />}></Route>
      <Route path="/addemployee/:id" element={<Add_employee />}></Route>
      <Route path="/addclientwork/:id" element={<Add_clientWork />}></Route>
    </Routes>
  );
};

export default Allroutes;
