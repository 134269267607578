import React, { useState } from "react";
import { useParams } from "react-router-dom";

const initialState = {
    officeID: "",
    clientID: "",
    workID: "",
    departmentID: "",
    status: "",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: "",
    employee: [],
    dueDate: "",
    nature: "",
    closed: false,
  };

const Add_clientWork = () => {
    const [workData, setWorkData] = useState({
        officeID: "",
        clientID: "",
        workID: "",
        departmentID: "",
        status: "",
        unbilled: "",
        billed: "",
        collected: "",
        outstanding: "",
        amount: "",
        employee: [],
        dueDate: "",
        nature: "",
        closed: false,
      });

      const {clientID,workID,departmentID,status,amount,dueDate,nature} = workData;
      const {id} = useParams();
      console.log(id);

    const handleChange = (e)=>{
      const {name,value} = e.target;
      setWorkData({...workData,[name]:value})
    }


    const addEmployee = () => {
      setWorkData({
        ...workData,
        employee: [...workData.employee, { userID: "", expense: "" }],
      });
    };

    const removeEmployee = (index) => {
      const updatedEmployee = [...workData.employee];
      updatedEmployee.splice(index, 1);
      setWorkData({ ...workData, employee: updatedEmployee });
    };
  
    // Function to handle changes in employee details
    const handleEmployeeChange = (index, event) => {
      const updatedEmployee = [...workData.employee];
      updatedEmployee[index][event.target.name] = event.target.value;
      setWorkData({ ...workData, employee: updatedEmployee });
    };

    const handleSubmit = (e)=>{
      e.preventDefault();
      console.log(workData)
    }

  return <div>
    <form action="" onSubmit={handleSubmit} >
    <select name="clientID" value={clientID} onChange={handleChange} id="" required>
    <option value="">Select a client</option>
        <option value="A">A</option>
        <option value="B">B</option>
    </select>
    <select name="workID" value={workID} onChange={handleChange} required>
    <option value="">Select a work</option>
        <option value="A">A</option>
        <option value="B">B</option>
    </select><select name="departmentID" value={departmentID} onChange={handleChange} required>
        <option value="">Select a department</option>
        <option value="A">A</option>
        <option value="B">B</option>
    </select><select name="status" value={status} onChange={handleChange} required>
    <option value="">Select status</option>
        <option value="A">A</option>
        <option value="B">B</option>
    </select>
        <br />
        <input
          value={amount}
          onChange={handleChange}
          name="amount"
          type="number"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter amount*"
          required
        />
        <br />
        <input
          value={dueDate}
          onChange={handleChange}
          name="dueDate"
          type="date"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter duedate*"
          required
        />
        <br />
       <select name="nature" onChange={handleChange} value={nature} id="" required>
        <option value="">Select nature of work</option>
        <option value="A">A</option>
        <option value="B">B</option>
       </select> <br />
       {workData.employee.map((emp, index) => (
          <div key={index}>
            <input
              type="text"
              name="userID"
              value={emp.userID}
              placeholder="Employee ID"
              onChange={(e) => handleEmployeeChange(index, e)} required
            />
            <input
              type="text"
              name="expense"
              value={emp.expense}
              placeholder="Expense"
              onChange={(e) => handleEmployeeChange(index, e)} required
            />
                <button type="button" onClick={() => removeEmployee(index)}>
              Remove
            </button>
          </div>
        ))}
         <button type="button" onClick={addEmployee}>
          Add Employee
        </button>
        <button type="submit">Submit</button>
    </form>
  </div>;
};

export default Add_clientWork;
