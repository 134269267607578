import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth"; // Import Firebase Authentication functions
// import firebaseConfig from "./firebaseConfig"; // Import your Firebase configuration
import { auth } from "../firebase.config";
// const auth = getAuth(firebaseConfig);

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [isResetRequested, setIsResetRequested] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email); // Send a password reset email
      setIsResetRequested(true);
      alert("Password reset email sent successfully!");
    } catch (error) {
      alert("Error sending password reset email:", error);
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      {!isResetRequested ? (
        <div>
          <p>Enter your email address to reset your password:</p>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <button onClick={handlePasswordReset}>Reset Password</button>
        </div>
      ) : (
        <p>Password reset email sent. Check your inbox for further instructions.</p>
      )}
    </div>
  );
};

export default PasswordReset;
