import React from "react";

const Dashboard_targetvsactual = () => {
  return (
    <div>
      <div className="absolute top-[466px] left-[356px] text-[32px] leading-[18px] font-semibold text-red-200 text-center flex items-center justify-center w-[293px] h-[68px]">
        Target Vs Actual
      </div>
      <div className="absolute top-[550px] left-[450px] text-[36px] font-medium text-black text-center inline-block">
        1.56k
      </div>
      <div className="absolute top-[619px] left-[334px] w-[328px] h-[43px] flex justify-center items-center rounded-[50px]  bg-violet-200">
        <div className="font-medium text-blackampwhite-white text-center bg-violet-500 inline-block w-[40.96%] h-[43px] rounded-[50px] flex justify-center items-center">
          40.96k
        </div>
        <div className="text-base leading-[140%] font-medium text-black text-center  inline-block w-[59.04%] h-[43px] rounded-[50px] flex justify-center items-center">
          59.04k
        </div>
      </div>
    </div>
  );
};

export default Dashboard_targetvsactual;
