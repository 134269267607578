import React from 'react'

const Dashboard_intro = () => {
  return (
    <div>
      <div className="mt-5 rounded-tl-none rounded-tr-2xl rounded-br-2xl rounded-bl-none bg-blackampwhite-white w-1" />
      <div className=" text-17xl font-extrabold text-darkslategray">
        Sales Dashboard
      </div>
      <div className=" text-base font-medium text-lightgray">
        Welcome back, Jeet
      </div>
    </div>
  )
}

export default Dashboard_intro
