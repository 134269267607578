// RegistrationComponent.js

import React, { useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";
import OTP from "./Tryotp";
import { Link, useNavigate } from "react-router-dom";
import { getLocalData } from "../Utils/LocalStorage";
import { useDispatch } from "react-redux";
import { getRegistration } from "../Redux/Authreducer/action";
// import firebaseConfig from "../firebase.config";

// const auth = getAuth(firebaseConfig);

const initialState = {
  name: "",
  email: "",
  password: "",
  phone: "",
  image: "",
  dateOfBirth: "",
  // otpverify:false
};

const RegistrationComponent = () => {
  const [text, setText] = useState(initialState);
  const [ph, setPh] = useState("");
  const [otpver, setOtpver] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { name, email, password, image, dateOfBirth, phone, otpverify } = text;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  useEffect(() => {
    setText({ ...text, phone: ph });
  }, [ph]);

  useEffect(() => {
    if (getLocalData("otptoken")) {
      setOtpver(true);
    }
  }, [otpver]);

  const handleRegistration = async () => {
    console.log(text);
    if (!otpver) {
      alert("Please verify your mobile no.");
    } else if (!/^[a-zA-Z ]*$/.test(name)) {
      alert("Your name shouldnot be contain any number.");
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      alert("You have entered a invalid email.")
    // } 
    // else if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(dateOfBirth)) {
    //   alert({
    //     status: false,
    //     message: "Date of birh should be in dd/mm/yyyy format.",
    //   });
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(password)) {
      alert("Password should be alphanumeric and contain one uppercase letter.");
    } else {
      try {
        await createUserWithEmailAndPassword(auth, email, password);

        dispatch(getRegistration(text))
          .then((res) => {
            alert("Registered successfully");
            navigate("/login");
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        alert(error.code);
      }
    }
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Registration</h2>
      <div className="mb-4">
        <input
          value={name}
          onChange={handleChange}
          name="name"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter username*"
          required
        />
        <input
          type="email"
          placeholder="Email"
          className="border p-2 w-full"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="Password"
          className="border p-2 w-full"
          name="password"
          value={password}
          onChange={handleChange}
        />
      </div>
      {otpver ? (
        <h1>Mobile no. verified, please register</h1>
      ) : (
        <OTP ph={ph} setPh={setPh} otpver={otpver} setOtpver={setOtpver} />
      )}
      <Link to={"/login"}><div>Already have an account ?Login</div></Link>
      <div>
        <button
          onClick={handleRegistration}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default RegistrationComponent;
