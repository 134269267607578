// import {applyMiddleware, legacy_createStore} from "redux"
// import thunk from "redux-thunk"
// import { reducer } from "./Hilton_reducer/reducer"

// const store = legacy_createStore(reducer,applyMiddleware(thunk))

// export {store}

import {applyMiddleware, legacy_createStore , combineReducers} from "redux" 
import { Authreducer } from "./Authreducer/reducer";
import {Officereducer} from "./OfficeReducer/reducer";
import {Clientreducer} from "./ClientReducer/reducer";
import {Employeereducer} from "./EmployeeReducer/reducer";
import {Clientworkreducer} from "./ClientWorkReducer/reducer";

import thunk from "redux-thunk";
 

const rootReducer = combineReducers({Authreducer,Officereducer,Clientreducer,Employeereducer, Clientworkreducer})
const store = legacy_createStore(rootReducer ,applyMiddleware(thunk))

export {store}
