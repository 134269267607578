import { getLocalData } from "../../Utils/LocalStorage";
import { serverUrl } from "../../api";
import * as types from "./actiontypes";
import axios from "axios";

export const createOffice = (data) => (dispatch) => {
  dispatch({ type: types.CREATE_OFFICE_REQUEST });
  return axios.post(`${serverUrl}/office/add`, data, {
      headers: {
        Authorization: getLocalData("maintoken"),
      },
    })
    .then((res) => {
      // console.log(res);
      return dispatch({ type: types.CREATE_OFFICE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({ type: types.CREATE_OFFICE_FAILURE });
    });
};

export const getOffice = (dispatch) => {
  dispatch({ type: types.GET_OFFICE_REQUEST });
  return axios.get(`${serverUrl}/office/`, {
      headers: {
         Authorization: getLocalData("maintoken"),
      },
    })
    .then((res) => {
      // console.log(res)
      return dispatch({ type: types.GET_OFFICE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_OFFICE_FAILURE });
    });
};

// export const verifyOtp = (data) => (dispatch) => {
//     dispatch({ type: types.OTP_REQUEST })
//     return axios.post(`${serverUrl}/user/verifyotp`, data)
//         .then((res) => {

//           return  dispatch({ type: types.OTP_SUCCESS, payload: res.data })
//         }).catch((err) => {
//            return dispatch({ type: types.OTP_FAILURE })
//         })
// }

// export const resendOtp = (data) => (dispatch) => {
//     dispatch({ type: types.OTP_REQUEST })
//     return axios.post(`${serverUrl}/user/resendotp`, data)
//         .then((res) => {

//           return  dispatch({ type: types.OTP_SUCCESS, payload: res.data })
//         }).catch((err) => {
//            return dispatch({ type: types.OTP_FAILURE })
//         })
// }
