import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ show, setter }) {
  //  const router = useRouter();

  // Define our base class
  const className =
    "bg-black w-[280px] transition-[margin-left] ease-in-out duration-500 fixed md:static top-0 bottom-0 left-0 z-40";
  // Append class based on state of sidebar visiblity
  const appendClass = show ? " ml-0" : " ml-[-280px] md:ml-0 border-0";

  // Clickable menu items
  const MenuItem = ({ icon, name, route }) => {
    return (
      <Link
        to={route}
        onClick={() => {
          setter((oldVal) => !oldVal);
        }}
        className={`flex gap-1 m-auto text-start w-[200px] text-md pl-6 py-3`}
      >
        <div className="flex justify-center items-center text-lg gap-5 mt-5">
          <img src={icon}  alt={"image"}className="max-w-full overflow-hidden max-h-full"/>
            
          <div className="font-medium text-sm text-thistle inline-block">{name}</div>
        </div>
      </Link>
    );
  };

  // Overlay to prevent clicks in background, also serves as our close button
  const ModalOverlay = () => (
    <div
      className={`flex md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
      onClick={() => {
        setter((oldVal) => !oldVal);
      }}
    />
  );

  return (
    <>
      <div
        className={`${className}${appendClass} shadow-slate-200 text-white bg-navy w-[280px] h-[900px]`}
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <div className="pt-5 flex flex-row items-center justify-center m-auto gap-[16px] text-21xl text-blackampwhite-white font-bold">
          <div className="rounded-[50%] [background:linear-gradient(#a9bf20,_#a9bf20),_rgba(217,_217,_217,_0.2)] box-border w-8 h-8 border-[5px] border-solid border-blackampwhite-white" />

          <Link to="/dashboard">
            <p>PGPMS</p>
          </Link>
        </div>
        <div className="flex flex-col mt-5 m-auto">
          <MenuItem
            name="Dashboard"
            route="/dashboard"
            icon={"/img/vector.svg"}
          />
          <MenuItem
            name="Customers"
            route="/connections"
            icon={"/img/vector1.svg"}
          />
          <MenuItem
            name="Activities"
            route="/connections"
            icon={"/img/activity.svg"}
          />
          <MenuItem
            name="Reports"
            route="/connections"
            icon={"/img/reports.svg"}
          />
        </div>
      </div>

      {show ? <ModalOverlay /> : <></>}
    </>
  );
}

// <div className=" rounded-xl bg-blackampwhite-white shadow-[0px_8px_8px_rgba(50,_50,_71,_0.08),_0px_8px_16px_rgba(50,_50,_71,_0.06)] w-[260px] overflow-hidden" />
// <div className="rounded-xl bg-blackampwhite-white shadow-[0px_8px_8px_rgba(50,_50,_71,_0.08),_0px_8px_16px_rgba(50,_50,_71,_0.06)] w-[260px] overflow-hidden" />
// <div className="rounded-xl bg-blackampwhite-white shadow-[0px_8px_8px_rgba(50,_50,_71,_0.08),_0px_8px_16px_rgba(50,_50,_71,_0.06)] w-[260px] overflow-hidden" />
// <div className="absolute top-[0px] left-[0px] bg-navy w-[250px] h-[900px]" />
// <div className="absolute top-[31px] left-[29px] flex flex-row items-center justify-start gap-[16px] text-21xl text-blackampwhite-white">
//   <div className="relative rounded-[50%] [background:linear-gradient(#a9bf20,_#a9bf20),_rgba(217,_217,_217,_0.2)] box-border w-8 h-8 border-[5px] border-solid border-blackampwhite-white" />
//   <div className="relative font-extrabold">PGPMS</div>
// </div>
// <div className="absolute top-[349px] left-[93px] font-medium text-thistle inline-block w-[133px] h-[17px]">
//   Reports
// </div>
// <div className="absolute top-[109px] left-[0px] w-[280px] h-[731px] text-thistle">
//   <div className="absolute top-[0px] left-[0px] w-[280px] h-[72px] text-blackampwhite-white">
//     <div className="absolute top-[25px] left-[55px] flex flex-row items-center justify-start">
//       <div className="relative w-[170.5px] h-[21.14px]">
//         <img
//           className="absolute top-[0px] left-[0px] w-[21.5px] h-[21.14px]"
//           alt=""
//           src="/img/vector.svg"
//         />
//         <div className="absolute top-[2.07px] left-[37.5px] font-medium inline-block w-[133px] h-[17px]">
//           Dashboard
//         </div>
//       </div>
//     </div>
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//   </div>
//   <div className="absolute top-[72px] left-[0px] w-[280px] h-[72px]">
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[24px] left-[93px] flex flex-row items-center justify-start">
//       <div className="relative w-[133px] h-[17px]">
//         <div className="absolute top-[0px] left-[0px] font-medium inline-block w-[133px] h-[17px]">
//           Customers
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="absolute top-[144px] left-[0px] w-[280px] h-[72px]">
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[26px] left-[94px] flex flex-row items-center justify-start">
//       <div className="relative w-[133px] h-[17px]">
//         <div className="absolute top-[0px] left-[0px] font-medium inline-block w-[133px] h-[17px]">
//           Activities
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="absolute top-[216px] left-[0px] w-[280px] h-[72px]">
//     <div className="absolute top-[25px] left-[55px]" />
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//   </div>
//   <div className="absolute top-[587px] left-[0px] w-[279px] h-[72px]">
//     <div className="absolute top-[0px] left-[0px] w-[279px] flex flex-row items-center justify-start">
//       <div className="relative w-[280px] h-[72px]">
//         <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//         <img
//           className="absolute top-[28px] left-[47px] w-6 h-6 overflow-hidden"
//           alt=""
//           src="/img/settings.svg"
//         />
//         <div className="absolute top-[27.07px] left-[92.5px] font-medium inline-block w-[133px] h-[17px]">
//           Settings
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="absolute top-[659px] left-[0px] w-[280px] h-[72px]">
//     <div className="absolute top-[0px] left-[0px] bg-gainsboro w-[280px] h-[72px] opacity-[0]" />
//     <div className="absolute top-[29px] left-[93px] flex flex-row items-center justify-start">
//       <div className="relative w-[133px] h-[17px]">
//         <div className="absolute top-[0px] left-[0px] font-medium inline-block w-[133px] h-[17px]">
//           Log out
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="absolute top-[205px] left-[77px] w-6 h-6 overflow-hidden" />
// <img
//   className="absolute h-[2.39%] w-[1.49%] top-[27.78%] right-[98.62%] bottom-[74.83%] left-[3.89%] max-w-full overflow-hidden max-h-full"
//   alt=""
//   src="/img/vector1.svg"
// />
// <img
//   className="absolute top-[280px] left-[55px] w-6 h-6 overflow-hidden"
//   alt=""
//   src="/img/activity.svg"
// />
// <img
//   className="absolute top-[348px] left-[56px] w-6 h-6 overflow-hidden"
//   alt=""
//   src="/img/reports.svg"
// />
// <img
//   className="absolute top-[799px] left-[48px] w-6 h-6 overflow-hidden"
//   alt=""
//   src="/img/logout.svg"
// />
// </div>
