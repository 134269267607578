import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase.config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { getLocalData } from "../Utils/LocalStorage";
import { getOffice } from "../Redux/OfficeReducer/action";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => {
    return {
      loginData: state.Authreducer.loginData,
    };
  });

  const { officeData } = useSelector((state) => {
    return {
      officeData: state.Officereducer.officeData,
    };
  });

  const handleSignout = () => {
    signOut(auth);
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
      } else {
        console.log("Signedout");
      }
    });
    console.log(loginData);
  }, []);

  useEffect(() => {
    dispatch(getOffice);
  }, []);
  return (
    <div>
      {/* <Link to={"/login"}><button>Login</button></Link>
      <Link to={"/register"}><button>Register</button></Link> */}
      <h1>This is home page</h1>
      <button onClick={handleSignout}>Sign out</button>
      <br />

      <br />

      {officeData && officeData.message && officeData.message.length > 0 ? (
        <Link to={"/officedashboard"}>
          <h1 style={{"color":"lightgreen"}}>See your office from here </h1>
        </Link>
      ) : (
        <Link to={"/officecreate"}><p style={{"color":"red"}}>You donot have an office, Let's create a office.</p></Link>
      )}

      <p>
        Are you a employee ? Waiting for someone who can invite you in a office
        ? Share this "{getLocalData("userID")}" to your CA.{" "}
      </p>
      <br />
    </div>
  );
};

export default Home;
