import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createClient } from "../Redux/ClientReducer/action";

const initialState = {
  client_name: "",
  form_of_organisation: "",
  address: "",
  city: "",
  state: "",
  mobile: "",
  turnover: "",
  pan_no: "",
  gst_no: "",
  employee_no: "",
  PF_no: "",
  officeID: ""
};

const Add_client = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [text, setText] = useState(initialState);

    const {client_name,form_of_organisation,address,city,state,mobile,turnover,pan_no,gst_no,employee_no,PF_no} = text

    
    const handleChange = (e)=>{
        const {name, value} = e.target;
        setText({...text,[name]:value})
    }

    useEffect(()=>{
        setText({...text,officeID:id})
      },[id])
    
    const handleSubmit = (e)=>{
        e.preventDefault()
        dispatch(createClient(text))
        .then((res) => {
            console.log(res);
            if (res.payload.status){
              alert(res.payload.message)
              navigate("/officedashboard")
              window.location.reload();
            } else {
              alert("Something went wrong")
            }
        })
        setText(initialState)
    }
  return (
    <div className="w-11/12 md:w-1/2 m-auto">
      <form action="" onSubmit={handleSubmit}>
        <input
          value={client_name}
          onChange={handleChange}
          name="client_name"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter client name*"
          required
        />
        <br />
        <input
          value={form_of_organisation}
          onChange={handleChange}
          name="form_of_organisation"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter form of organisation*"
          required
        />
        <br />
        <input
          value={address}
          onChange={handleChange}
          name="address"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter address*"
          required
        />
        <br />
        <input
          value={city}
          onChange={handleChange}
          name="city"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter city*"
          required
        />
        <br />
        <input
          value={state}
          onChange={handleChange}
          name="state"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter state*"
          required
        />
        <br />
        <input
          value={mobile}
          onChange={handleChange}
          name="mobile"
          type="number"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter mobile*"
          required
        />
        <br />
        <input
          value={turnover}
          onChange={handleChange}
          name="turnover"
          type="number"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter turnover*"
          required
        />
        <br />
        <input
          value={pan_no}
          onChange={handleChange}
          name="pan_no"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter pan_no*"
          required
        />
        <br />
        <input
          value={gst_no}
          onChange={handleChange}
          name="gst_no"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter gst_no*"
          required
        />
        <br />
        <input
          value={employee_no}
          onChange={handleChange}
          name="employee_no"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter employee_no*"
          required
        />
        <br />
        <input
          value={PF_no}
          onChange={handleChange}
          name="PF_no"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter PF_no*"
          required
        />
        <br />

        <button type="submit">Add office</button>
      </form>
    </div>
  );
};

export default Add_client;
