import React from 'react'


export default function MobileMenuBar({ setter }) {
    return (
        <nav className="md:hidden z-20 fixed px-2 bg-white">
            <img src="https://github.com/Hilton-Borah/dummyImageforUrbanGuys/assets/103739534/cd977c0a-ab0d-4060-8f8e-adc3e6d0608a" className="text-4xl flex text-black"
                onClick={() => {
                    setter(oldVal => !oldVal);
                }}
            />
        </nav>
    )
}