
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
const data = [
  {
    set: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    set: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    set: "March",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    set: "April",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    set: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    set: "May",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    set: "Jun",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Dashboard_Chart = () => {
  return (
    <div className="absolute left-[700px] top-[460px] border-[3px] border-solid border-lightsteelblue p-5 rounded-xl">
      <div className="font-medium mb-5 ml-7">Total Sales</div>
    <LineChart
      width={660}
      height={200}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid vertical={false} strokeDasharray={1} />
      <XAxis dataKey="set" />
      <YAxis />
      <Tooltip/>
      <Legend />
      <Line type="monotone"  dataKey="pv" stroke="#FF6C98" strokeWidth={3} dot={false} />
      <Line type="monotone" dataKey="uv" stroke="#170C60" strokeWidth={3} dot={false} />
    </LineChart>
    </div>
  );
};

export default Dashboard_Chart;
