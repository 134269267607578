import React from 'react'
import Dashboard_topClientcard from './Dashboard_topClientcard'

const Dashboard_topClients = () => {
  return (
    <div className='absolute top-[780px] left-[340px] m-auto text-center'>
        <div className='text-5xl text-center leading-[22px] font-semibold text-navy text-center inline-block w-[329px] h-[37px]'>Top 10 clients</div>
    <div className=' flex justify-between items-center gap-5 m-auto'>
      <Dashboard_topClientcard img={"/img/frame-1344.svg"} heading={"Stock trading"} client={"Foursquare"}/>
      <Dashboard_topClientcard img={"/img/frame-1344.svg"} heading={"Stock trading"} client={"Foursquare"}/>
      <Dashboard_topClientcard img={"/img/frame-1342.svg"} heading={"Stock trading"} client={"Kickstarter"}/>
      <Dashboard_topClientcard img={"/img/frame-1345.svg"} heading={"Stock trading"} client={"Google"}/>
    </div>
    </div>
  )
}

export default Dashboard_topClients
