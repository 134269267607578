import { useMemo } from "react";

const BilledContainer = ({
  transactionStatus,
  transactionCount,
  transactionIcon8,
  percentageCompleted,
  propLeft,
  propBorder,
  propColor,
  propLeft1,
  propWidth,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const rectangleDivStyle = useMemo(() => {
    return {
      border: propBorder,
    };
  }, [propBorder]);

  const billedStyle = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  const div15Style = useMemo(() => {
    return {
      left: propLeft1,
      width: propWidth,
    };
  }, [propLeft1, propWidth]);

  return (
    <div
      className="absolute top-[0px] left-[0px] w-[223.96px] h-[113px] text-center text-5xl text-midnightblue font-semibold"
      style={groupDivStyle}
    >
      <div className="absolute top-[0px] left-[0px] w-[223.96px] h-[113px]">
        <div
          className="absolute top-[0px] left-[0px] rounded-mini bg-blackampwhite-white shadow-[0px_3.500000238418579px_5.5px_rgba(0,_0,_0,_0.02)] box-border w-[223.96px] h-[113px] border-[1px] border-solid border-black"
          style={rectangleDivStyle}
        />
        <div className="absolute top-[9px] left-[13.42px] w-[194.03px] h-[82.85px]">
          <div
            className="absolute top-[0px] left-[0px] leading-[150%] uppercase font-semibold inline-block w-[194.03px] h-[23px]"
            style={billedStyle}
          >
            {transactionStatus}
          </div>
          <div className="absolute top-[41px] left-[4.13px] text-21xl leading-[140%] font-semibold text-gray-gray-700 text-left inline-block w-[156.85px] h-[41.85px]">
            {transactionCount}
          </div>
        </div>
        <img
          className="absolute top-[61px] left-[152.41px] w-[63.42px] h-[46.38px]"
          alt=""
          src={transactionIcon8}
        />
      </div>
      <div
        className="absolute top-[66px] left-[166.16px] text-xl leading-[140%] text-ghostwhite inline-block w-[41.28px] h-[19px]"
        style={div15Style}
      >
        {percentageCompleted}
      </div>
    </div>
  );
};

export default BilledContainer;
