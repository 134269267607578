export const workTable = [
  {
    id: 1,
    clientname: "Tata",
    workname: "A",
    department: "Income tax",
    status: "Lead",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 200,
    employee: [
      {
        name: "rajashree",
        expense: 100,
      },
      {
        name: "hilton",
        expense: 50,
      },
    ],
    duedate: new Date("2023-10-01 23:59:59"),
    nature: "Futuristic",
    createDate: new Date("2023-9-01 23:59:59"),
  },
  {
    id: 2,
    clientname: "Google",
    workname: "B",
    department: "GST",
    status: "Billable",
    unbilled: 80,
    billed: 100,
    collected: 50,
    outstanding: 50,
    amount: 180,
    employee: [
      {
        name: "Tarun",
        expense: 70,
      },
      {
        name: "Rajiv",
        expense: 60,
      },
    ],
    duedate: new Date("2023-11-11 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-9-01 23:59:59"),
  },
  {
    id: 3,
    clientname: "Microsoft",
    workname: "C",
    department: "MCA",
    status: "wip",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 150,
    employee: [
      {
        name: "Himanshu",
        expense: 70,
      },
      {
        name: "Mrinmoy",
        expense: 60,
      },
    ],
    duedate: new Date("2023-11-10 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-9-01 23:59:59"),
  },
  {
    id: 4,
    clientname: "Infosys",
    workname: "A",
    department: "Report",
    status: "Not started",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 170,
    employee: [
      {
        name: "Rashmi",
        expense: 60,
      },
      {
        name: "Jayant",
        expense: 60,
      },
    ],
    duedate: new Date("2023-9-30 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-9-22 23:59:59"),
  },
  {
    id: 5,
    clientname: "Deepthought",
    workname: "D",
    department: "GST",
    status: "Billable",
    unbilled: 50,
    billed: 200,
    collected: 120,
    outstanding: 80,
    amount: 250,
    employee: [
      {
        name: "Gautam",
        expense: 150,
      },
    ],
    duedate: new Date("2023-9-25 23:59:59"),
    nature: "Futuristic",
    createDate: new Date("2023-9-22 23:59:59"),
  },
  {
    id: 6,
    clientname: "ITC",
    workname: "E",
    department: "Report",
    status: "Not started",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 300,
    employee: [
      {
        name: "Munna",
        expense: 150,
      },
    ],
    duedate: new Date("2023-10-10 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-9-10 23:59:59"),
  },
  {
    id: 7,
    clientname: "Paytm",
    workname: "A",
    department: "GST",
    status: "Billable",
    unbilled: 100,
    billed: 350,
    collected: 200,
    outstanding: 150,
    amount: 450,
    employee: [
      {
        name: "Rajat",
        expense: 200,
      },
    ],
    duedate: new Date("2023-10-15 23:59:59"),
    nature: "Futuristic",
    createDate: new Date("2023-8-10 23:59:59"),
  },
  {
    id: 8,
    clientname: "Practice Guru",
    workname: "D",
    department: "GST",
    status: "Lead",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 160,
    employee: [
      {
        name: "Maina",
        expense: 100,
      },
    ],
    duedate: new Date("2023-9-29 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-7-10 12:59:59"),
  },
  {
    id: 9,
    clientname: "GEOGO",
    workname: "B",
    department: "MCA",
    status: "wip",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 220,
    employee: [
      {
        name: "Rashmi",
        expense: 80,
      },
      {
        name: "Jayant",
        expense: 100,
      },
    ],
    duedate: new Date("2023-9-12 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-8-5 23:59:59"),
  },
  {
    id: 10,
    clientname: "Amazon",
    workname: "A",
    department: "Income tax",
    status: "Not started",
    unbilled: "",
    billed: "",
    collected: "",
    outstanding: "",
    amount: 400,
    employee: [
      {
        name: "Ranjit",
        expense: 200,
      },
    ],
    duedate: new Date("2023-9-15 23:59:59"),
    nature: "Maintenance",
    createDate: new Date("2023-8-12 23:59:59"),
  },
];
