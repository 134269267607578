// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDOLd6vlnsbnHVBkPrRh4fb9c8CUnlzbMU",
  authDomain: "practice-guru-otp.firebaseapp.com",
  projectId: "practice-guru-otp",
  storageBucket: "practice-guru-otp.appspot.com",
  messagingSenderId: "68710331188",
  appId: "1:68710331188:web:bd6ef2edd2ecf8bdc26b61",
  measurementId: "G-GYNSMWEJ1E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);