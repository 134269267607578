// LoginComponent.js

import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";
import { Link, useNavigate } from "react-router-dom";
import { getLogin } from "../Redux/Authreducer/action";
import { useDispatch, useSelector } from "react-redux";
import { saveLocalData } from "../Utils/LocalStorage";
// import firebaseConfig from "../firebase.config";

// const auth = getAuth(firebaseConfig);

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {loginData} = useSelector((state)=>{
    return {
      loginData : state.Authreducer.loginData
    }
  })

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      //  await signInWithEmailAndPassword(auth, email, password);
      dispatch(getLogin({email,password}))
        .then((res) => {
          saveLocalData("maintoken", res.payload.token)
          saveLocalData("userID", res.payload.userID)
          alert("User logged in successfully!");
          navigate("/home");
        })
        .catch((err) => {
          console.log(err);
        });
      // console.log(kela)
    } catch (error) {
      alert(error.code);
    }
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Login</h2>
      <div className="mb-4">
        <input
          type="email"
          placeholder="Email"
          className="border p-2 w-full"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div className="mb-4">
        <input
          type="password"
          placeholder="Password"
          className="border p-2 w-full"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <Link to={"/forgotpassword"}>
        <div>Forgot password ?</div>
      </Link>
      <div>
      <Link to={"/register"}><div>Donot have an account ? register</div></Link>
        <button
          onClick={handleLogin}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginComponent;
