
import Dashboard_Chart from './componants/CS_Dashboard/Dashboard_Chart';
import Allroutes from './pages/Allroutes';

function App() {
  // const dueDate = new Date('2023-10-31 23:59:59');
  return (
    <div className="App">
      <Allroutes/>
      {/* <Dummy/> */}
      {/* <Dashboard_Chart/> */}
      {/* <CountdownTimer dueDate={dueDate}/> */}
      {/* <OTP/> */}
     {/* <Home/> */}
     {/* <RegistrationComponent/> */}
     {/* <LoginComponent/> */}
    </div>
  );
}

export default App;
