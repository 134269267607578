import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { getOffice } from "../Redux/OfficeReducer/action";
import { getClient } from "../Redux/ClientReducer/action";
import { getEmployee } from "../Redux/EmployeeReducer/action";
import Employee from "../componants/Employee";
import { workTable } from "../componants/dummyData";
import Dashboard_saleTable from "../componants/CS_Dashboard/Dashboard_saleTable";
import Dashboard_saleCompare from "../componants/CS_Dashboard/Dashboard_saleCompare";
import Dashboard_topRevenue from "../componants/CS_Dashboard/Dashboard_topRevenue";
import Dashboard_saleClientwise from "../componants/CS_Dashboard/Dashboard_saleClientwise";
import Dashborad_futureorMainteinence from "../componants/CS_Dashboard/Dashborad_futureorMainteinence";
import Dashboard_employeeListwithGP from "../componants/CS_Dashboard/Dashboard_employeeListwithGP";
import Dashboard_exployeeExpensesWithClientname from "../componants/CS_Dashboard/Dashboard_exployeeExpensesWithClientname";
import Dashboard_top3profitmakingClient from "../componants/CS_Dashboard/Dashboard_top3profitmakingClient";
import Dashboard_outStandingreport from "../componants/CS_Dashboard/Dashboard_outStandingreport";
import CountdownTimer from "./Countdown";
import Dashboard_dueDates from "../componants/CS_Dashboard/Dashboard_dueDates";
import MobileMenuBar from "../componants/CS_Dashboard/Dashboard_mobileMenubar";
import Sidebar from "../componants/CS_Dashboard/Dashboard_sidebar";
import Dashboard_intro from "../componants/CS_Dashboard/Dashboard_intro";
import Dashboard_targetvsactual from "../componants/CS_Dashboard/Dashboard_targetvsactual";
import Dashboard_Chart from "../componants/CS_Dashboard/Dashboard_Chart";
import Dashboard_topClients from "../componants/CS_Dashboard/Dashboard_topClients";

const Officedashboard = () => {
  const dispatch = useDispatch();
  const [searchparams, setSearchparams] = useSearchParams();
  const [showSidebar, setShowSidebar] = useState(false);
  const { officeData } = useSelector((state) => {
    return {
      officeData: state.Officereducer.officeData,
    };
  });

  const { clientData } = useSelector((state) => {
    return {
      clientData: state.Clientreducer.clientData,
    };
  });

  const { employeeData } = useSelector((state) => {
    return {
      employeeData: state.Employeereducer.employeeData,
    };
  });

  useEffect(() => {
    dispatch(getOffice);
  }, []);

  useEffect(() => {
    setSearchparams({
      id: officeData && officeData.message && officeData.message[0]._id,
    });
  }, [officeData]);

  // console.log(searchparams.getAll("id")[0])
  useEffect(() => {
    dispatch(getClient(searchparams.getAll("id")[0]));
    dispatch(getEmployee(searchparams.getAll("id")[0]));
  }, [searchparams]);

  // employee wise expense ----------------------

  // const employeeExpenses = workTable.reduce((result, item) => {
  //   item.employee.forEach((employee) => {
  //     const { name, expense } = employee;
  //     if (!result[name]) {
  //       result[name] = 0; // Initialize the total expense to 0 if it doesn't exist
  //     }
  //     result[name] += expense; // Add the expense to the total for this employee
  //   });
  //   return result;
  // }, {});

  // console.log(employeeExpenses)

  return (
    <div className="flex gap-0 md:gap-14">
      <div>
        <MobileMenuBar setter={setShowSidebar} />
        <Sidebar show={showSidebar} setter={setShowSidebar} />
      </div>
      <div className=" m-auto md:m-0">
        <Dashboard_intro />
        <Dashboard_saleTable />
        {/* <div className="flex justify-between align-center"> */}
          <Dashboard_targetvsactual />
          <Dashboard_Chart />
        <Dashboard_topClients/>
      </div>
    </div>
  );
};

export default Officedashboard;

// {officeData &&
//   officeData.message &&
//   officeData.message.map((el) => {
//     return (
//       <div className="font-bold m-5" key={el._id}>
//         Office name: {el.officename}
//       </div>
//     );
//   })}
// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />
// <div className="flex justify-center align-center gap-10">
//   <Link to={`/addclient/${searchparams.getAll("id")[0]}`}>
//     <button className="border px-5 py-1">Add a client</button>
//   </Link>
//   <Link to={`/addemployee/${searchparams.getAll("id")[0]}`}>
//     <button className="border px-5 py-1">Add your employee</button>
//   </Link>
//   <Link to={`/addclientwork/${searchparams.getAll("id")[0]}`}>
//     <button className="border px-5 py-1">Add your clientwork</button>
//   </Link>
// </div>
// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />
// <div>
//   <p className="font-semibold m-5">Client list</p>
//   {clientData &&
//     clientData.message &&
//     clientData.message.map((el) => {
//       return (
//         <ul key={el._id}>
//           <li>{el.client_name}</li>
//         </ul>
//       );
//     })}
// </div>
// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />
// <div>
//   <p className="font-semibold m-5"> Employee list</p>
//   {employeeData &&
//     employeeData.message &&
//     employeeData.message.map((el) => {
//       return (
//         <div key={el._id}>
//           <Employee
//             userID={el.userID}
//             position={el.organisation[0].position}
//           />
//         </div>
//       );
//     })}
// </div>

// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />

// {/* sale table---------------------- */}

// <Dashboard_saleTable />

// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />

// <div className="flex justify-evenly items-center">
//   {/* slaes perventage ------------------------ */}

//   <Dashboard_saleCompare />

//   {/* top 10 highest revenue ------------------------------ */}

//   <Dashboard_topRevenue />
// </div>

// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />

// <div className="flex justify-evenly items-center">
//   <div>
//     {/* top 3 profit and loss making client ------------------------ */}
//     <Dashboard_top3profitmakingClient one={0} two={3} What={"Profit"} />
//     <Dashboard_top3profitmakingClient one={-3} What={"Loss"} />
//   </div>

//   {/* Employee list with total expense and client name ---------------- */}

//   <Dashboard_exployeeExpensesWithClientname />
// </div>

// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />

// <div className="flex justify-evenly items-center">
//   {/* sales department -------------- */}
//   <Dashboard_saleClientwise />

//   <div>
//     {/* futuristic and maintainence --------------------- */}

//     <Dashborad_futureorMainteinence />

//     {/* Sales with	Employee Cost */}

//     <Dashboard_employeeListwithGP />
//   </div>
// </div>

// <hr className="mt-5 mb-5 border-2 w-11/12 m-auto" />

// {/* outstanding report -------------------- */}

// <Dashboard_outStandingreport />

// <Dashboard_dueDates />
