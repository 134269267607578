import { getLocalData } from "../../Utils/LocalStorage";
import { serverUrl } from "../../api";
import * as types from "./actiontypes";
import axios from "axios";

export const createEmployee = (data) => (dispatch) => {
  dispatch({ type: types.CREATE_EMPLOYEE_REQUEST });
  return axios.post(`${serverUrl}/employee/add`, data, {
      headers: {
        Authorization: getLocalData("maintoken"),
      },
    })
    .then((res) => {
      // console.log(res);
      return dispatch({ type: types.CREATE_EMPLOYEE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({ type: types.CREATE_EMPLOYEE_FAILURE });
    });
};


export const getEmployee=(id)=> (dispatch) => {
  dispatch({ type: types.GET_EMPLOYEE_REQUEST });
  return axios.get(`${serverUrl}/employee/${id}`)
    .then((res) => {
      // console.log(res)
      return dispatch({ type: types.GET_EMPLOYEE_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      return dispatch({ type: types.GET_EMPLOYEE_FAILURE });
    });
};
