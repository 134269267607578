import React from 'react'

const Dashboard_topClientcard = ({img,heading, client}) => {
  return (
    <div className='flex justify-center items-center gap-5 w-[250px] p-2 rounded-xl' style={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"}}>
      <img className="rounded-xl w-12 h-12" src={img} alt="" />
      <div>
        <div className='text-[#d1d2d3]'>{heading}</div>
        <div className='font-semibold text-light-black mt-2'>{client}</div>
      </div>
    </div>
  )
}

export default Dashboard_topClientcard
