import React, { useEffect, useState } from "react";
import { getLocalData } from "../Utils/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { createOffice, getOffice } from "../Redux/OfficeReducer/action";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const initialState = {
  officename: "",
  address: "",
  city: "",
  mobile: "",
  officeType: "",
  tel: "",
  userID: "",
};

const CA_addOfficepage = () => {
  const [text, setText] = useState(initialState);
  const dispatch = useDispatch();
  const [test, settest] = useState(false);
 

  useEffect(() => {
    setText({ ...text, userID: getLocalData("userID") });
  }, []);

  useEffect(()=>{
    if (getLocalData("userID")){
      settest(true)
    }
  },[])


  const { officename, address, city, mobile, officeType, tel } = text;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(text);
    dispatch(createOffice(text)).then((res) => {
      console.log(res);
      if (res.payload.status){
        alert(res.payload.message);
        settest(true)
      } else {
        alert("Something went wrong")
      }
    });
    setText(initialState)
  };



  return (
    <div>
      <h1>Add your virtual office from here</h1>


      <form action="" onSubmit={handleSubmit}>
        <input
          value={officename}
          onChange={handleChange}
          name="officename"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter officename*"
          required
        />
        <br />
        <input
          value={city}
          onChange={handleChange}
          name="city"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter cityname*"
          required
        />
        <br />
        <textarea
          value={address}
          onChange={handleChange}
          name="address"
          type="text"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter address*"
          required
        />
        <br />
        <select
          name="officeType"
          id=""
          value={officeType}
          onChange={handleChange}
        >
          <option value="">Select one</option>
          <option value="One">One</option>
          <option value="Two">Two</option>
          <option value="Three">Three</option>
        </select>
        <input
          value={mobile}
          onChange={handleChange}
          name="mobile"
          type="number"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter mobile no.*"
          required
        />
        <input
          value={tel}
          onChange={handleChange}
          name="tel"
          type="number"
          className="w-full px-3 py-2 border rounded-lg"
          placeholder="Enter telephone no.*"
          required
        />
        <button type="submit">Add office</button>
      </form>
    </div>
  );
};

export default CA_addOfficepage;
