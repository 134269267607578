import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createEmployee } from '../Redux/EmployeeReducer/action';

const EmployeeForm = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState({
    userID: "",
    organisation: [{ officeID: id, position: '' }],
  });

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedOrganisation = [...employeeData.organisation];
    updatedOrganisation[index][name] = value;
    setEmployeeData({ ...employeeData, organisation: updatedOrganisation });
  };

//   const handleAddOffice = () => {
//     setEmployeeData({
//       ...employeeData,
//       organisation: [...employeeData.organisation, { officeID: '', position: '' }],
//     });
//   };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(createEmployee(employeeData))
    .then((res)=>{
        if (res.payload.status){
            alert(res.payload.message);
            navigate("/officedashboard")
            window.location.reload();
          } else {
            alert(res.payload.message)
          }
    })
  }

  return (
    <div>
      <Link to={"/officedashboard"}><div>Go to office dashboard</div></Link>
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          name="userID"
          value={employeeData.userID}
          placeholder='Enetr employee ID'
          onChange={(event) => setEmployeeData({ ...employeeData, userID: event.target.value })}
        />
      </div>
      <div>
        {employeeData.organisation.map((office, index) => (
          <div>
            <input
              type="text"
              name="position"
              placeholder="Enter position"
              value={office.position}
              onChange={(event) => handleInputChange(index, event)}
            />
          </div>
        ))} 
        {/* <button type="button" onClick={handleAddOffice}>Add Office</button> */}
      </div>
      <button type="submit">Add employee</button>
    </form>
    </div>
  );
};

export default EmployeeForm;
