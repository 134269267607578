import React, { useEffect, useState } from "react";
import { workTable } from "../dummyData";
import BilledContainer from "./Dashboard_billedContainer";

const Dashboard_saleTable = () => {
  const [lead, setlead] = useState(0);
  const [billable, setBillable] = useState(0);
  const [not_started, setNot_started] = useState(0);
  const [wip, setWIP] = useState(0);

  let totalAmount = workTable.reduce((ac, el) => {
    return el.amount + ac;
  }, 0);

  useEffect(() => {
    let lead = 0;
    let wip = 0;
    let billable = 0;
    let not_started = 0;

    workTable.forEach((el) => {
      if (el.status === "Lead") {
        lead += el.amount;
      } else if (el.status === "wip") {
        wip += el.amount;
      } else if (el.status === "Billable") {
        billable += el.amount;
      } else if (el.status === "Not started") {
        not_started += el.amount;
      }

      setlead(lead);
      setBillable(billable);
      setWIP(wip);
      setNot_started(not_started);
    });
  }, []);

  let totalBilled = workTable.reduce((ac, el) => {
    return Number(el.billed) + ac;
  }, 0);

  let totalunBilled = workTable.reduce((ac, el) => {
    return Number(el.unbilled) + ac;
  }, 0);

  let totalunCollected = workTable.reduce((ac, el) => {
    return Number(el.collected) + ac;
  }, 0);

  let totalunoutStading = workTable.reduce((ac, el) => {
    return Number(el.outstanding) + ac;
  }, 0);
  return (
    <div className="">
      <div className="absolute w-[347px] h-[279px] text-center text-5xl text-yellowgreen mt-8">
        <div className=" w-[339px] h-[279px]">
          <div className="absolute top-[0px] rounded-3xl bg-navy box-border w-[328px] h-[300px] border-[1px] border-solid border-mediumslateblue-200" />
          <div className="absolute top-[17.51px] left-[8px] font-semibold inline-block w-[331px] h-[24.68px]">
            Month Sales - Sep, 23
          </div>
          <b className="absolute top-[71.21px] left-[0px] text-29xl inline-block text-blackampwhite-white w-[325px] h-[84.05px]">
            10.68k
          </b>
        </div>
        <div className="absolute top-[180.93px] left-[6px] text-xl text-gray-gray-400">
          <div className="absolute top-[0px] m-auto left-[0px] rounded-mini bg-blackampwhite-white shadow-[0px_3.500000238418579px_5.5px_rgba(0,_0,_0,_0.02)] w-[316.81px] h-[110.9px]" />
          <div className="absolute top-[8.17px] left-[54.66px] leading-[150%] inline-block w-[207.49px] h-[23.35px]">
            Previous Month
          </div>
          <div className="absolute top-[47.86px] left-[6.07px] text-17xl leading-[140%] font-medium text-gray-gray-700 inline-block w-[304.66px] h-[36.19px]">
            8.56k
          </div>
          <div className="absolute top-[65.37px] left-[227.74px] leading-[140%] font-semibold text-green-green-400 text-left inline-block w-[105.26px] h-[22.18px]">
            +55%
          </div>
        </div>
      </div>
      <div>
        <div className="absolute top-[129px] left-[699px] w-[708px] h-[114px]">
          <BilledContainer
            transactionStatus="Billed"
            transactionCount="7.75k"
            transactionIcon8="/img/icon.svg"
            percentageCompleted="75%"
          />
          <BilledContainer
            transactionStatus="Collected"
            transactionCount="5.88k"
            transactionIcon8="/img/icon1.svg"
            percentageCompleted="58%"
            propLeft="240.47px"
            propBorder="1px solid #0a8f27"
            propColor="#0a7d5b"
            propLeft1="154.81px"
            propWidth="57.8px"
          />
          <BilledContainer
            transactionStatus="Over Due"
            transactionCount="2.09k"
            transactionIcon8="/img/icon2.svg"
            percentageCompleted="28%"
            propLeft="484.04px"
            propBorder="1px solid #f41717"
            propColor="#f51616"
            propLeft1="159.97px"
            propWidth="50.57px"
          />
        </div>
        <div className="absolute top-[265px] left-[699px] w-[708px] h-[161px] text-center text-5xl text-mediumslateblue-100 font-semibold">
          <div className="absolute top-[0px] left-[0px] rounded-xl bg-blackampwhite-white w-[708px] h-[161px]" />
          <div className="absolute top-[54.83px] left-[15.8px] w-[214.28px] h-[98.34px]">
            <div className="absolute top-[0px] left-[0px] w-[214.28px] h-[98.34px]">
              <div className="absolute top-[0px] left-[0px] rounded-mini bg-blackampwhite-white shadow-[0px_3.500000238418579px_5.5px_rgba(0,_0,_0,_0.02)] box-border w-[214.28px] h-[98.34px] border-[3px] border-solid border-lightsteelblue" />
              <div className="absolute top-[7.83px] left-[6.91px] w-[197.49px] h-[84.42px]">
                <div className="absolute top-[0px] left-[5.92px] leading-[150%] uppercase font-semibold inline-block w-[185.64px] h-[20.02px]">
                  Lead
                </div>
                <div className="absolute top-[35.68px] left-[0px] text-21xl leading-[140%] font-medium text-gray-gray-700 inline-block w-[197.49px] h-[48.74px]">
                  {lead}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[54.83px] left-[246.86px] w-[214.28px] h-[98.34px] text-seagreen">
            <div className="absolute top-[0px] left-[0px] w-[214.28px] h-[98.34px]">
              <div className="absolute top-[0px] left-[0px] rounded-mini bg-blackampwhite-white shadow-[0px_3.500000238418579px_5.5px_rgba(0,_0,_0,_0.02)] box-border w-[214.28px] h-[98.34px] border-[3px] border-solid border-aquamarine" />
              <div className="absolute top-[7.83px] left-[1.97px] w-[203.41px] h-[72.23px]">
                <div className="absolute top-[0px] left-[10.86px] leading-[150%] uppercase font-semibold inline-block w-[185.64px] h-[20.02px]">
                  WIP
                </div>
                <div className="absolute top-[35.68px] left-[0px] text-21xl leading-[140%] font-semibold text-gray-gray-700 inline-block w-[203.41px] h-[36.55px]">
                  {wip}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[54.83px] left-[481.87px] w-[214.28px] h-[98.34px] text-red-200">
            <div className="absolute top-[0px] left-[0px] w-[214.28px] h-[98.34px]">
              <div className="absolute top-[0px] left-[0px] rounded-mini bg-blackampwhite-white shadow-[0px_3.500000238418579px_5.5px_rgba(0,_0,_0,_0.02)] box-border w-[214.28px] h-[98.34px] border-[3px] border-solid border-lightpink" />
              <div className="absolute top-[7.83px] left-[6.91px] w-[194.53px] h-[72.23px]">
                <div className="absolute top-[0px] left-[5.92px] leading-[150%] uppercase font-semibold inline-block w-[185.64px] h-[20.02px]">
                  UnBillable
                </div>
                <div className="absolute top-[35.68px] left-[0px] text-21xl leading-[140%] font-semibold text-gray-gray-700 inline-block w-[194.53px] h-[36.55px]">
                  89k
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-[7px] left-[236px] leading-[140%] font-semibold text-navy inline-block w-[221px] h-6">
            Convert to Sales
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard_saleTable;

// <table className="border m-auto">
// <thead className="border">
//   <tr>
//     <th className="px-10 py-1 border">Total amount</th>
//     <th className="px-10 py-1 border">Lead</th>
//     <th className="px-10 py-1 border">Not started</th>
//     <th className="px-10 py-1 border">WIP</th>
//     <th className="px-10 py-1 border">Billable</th>
//     <th className="px-10 py-1 border">Unbilled</th>
//     <th className="px-10 py-1 border">Billed</th>
//     <th className="px-10 py-1 border">Collected</th>
//     <th className="px-10 py-1 border">Outstanding</th>
//   </tr>
// </thead>
// <tbody className="border">
//   <tr>
//     <td>{totalAmount}</td>
//     <td>{lead}</td>
//     <td>{not_started}</td>
//     <td>{wip}</td>
//     <td>{billable}</td>
//     <td>{totalunBilled}</td>
//     <td>{totalBilled}</td>
//     <td>{totalunCollected}</td>
//     <td>{totalunoutStading}</td>
//   </tr>
//   <tr>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         return (
//           <div>
//             {el.clientname} :-
//             {el.amount}
//           </div>
//         );
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.status === "Lead") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.amount}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.status === "Not started") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.amount}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.status === "wip") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.amount}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.status === "Billable") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.amount}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.unbilled != "") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.unbilled}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.billed != "") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.billed}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.collected != "") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.collected}
//             </div>
//           );
//         }
//       })}
//     </td>
//     <td className="px-2 border">
//       {workTable.map((el) => {
//         if (el.outstanding != "") {
//           return (
//             <div>
//               {el.clientname} :-
//               {el.outstanding}
//             </div>
//           );
//         }
//       })}
//     </td>
//   </tr>
// </tbody>
// </table>
